
































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import { FileVideo, FileCheckedStatus } from '../../interface';

const VideosListItem = () => import('../VideoListItem/VideoListItem.vue');
const Tooltip = () => import('@/components/Tooltip/index.vue');

@Component({
  components: {
    VideosListItem,
    Tooltip,
  },
})
export default class VideoList extends Vue {
  @Prop({ required: true }) fileItem!: Array<FileVideo>;
  @Prop({ required: true }) allChecked!: Array<FileCheckedStatus>;
  @Prop({ required: true }) currentChecked!: boolean;

  get fileId() {
    return this.$route.params.fileID;
  }

  isCurrentVideo(id: string | number): boolean {
    return String(id) === String(this.fileId);
  }

  isChecked(id: number) {
    const result = this.allChecked.some((checkedItem: FileCheckedStatus) => (checkedItem?.idobject === id ? checkedItem.checked : false));

    return result;
  }

  handleClickVideoFromList(file: FileVideo) {
    this.emitClickVideoFromList();
    this.redirectToVideo(file);
  }

  emitClickVideoFromList() {
    this.$emit('click-video-list');
  }

  redirectToVideo(file: FileVideo) {
    const { idVideo, slug } = file;
    const { context, path, topicID } = this.$route.params;

    this.$router.push({
      name: 'videos',
      params: {
        context,
        path,
        topicID,
        fileID: String(idVideo),
        slugFile: slug,
      },
    });
  }
}
